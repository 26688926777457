// Depends on modal_trigger_controller.js
// this is an 'outlet' for modal_trigger_controller
// Use Stimulus Outlets API for triggering - https://stimulus.hotwired.dev/reference/outlets
/*

<button
  data-controller="modal-trigger"
  data-action="click->modal-trigger#openModal"
  data-modal-trigger-modal-outlet="#unique_selector_id" OR any CSS selector of your choice
>
  Click here
</button>

<div data-controller='modal' id="selector_id" class='generic-modal' hidden>
  <div class='modal-content'>
    <h1>Modal Header</h1>
    <p>Hello world</p>
  </div>
</div>

*/

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    isOpen: Boolean,
    resetIframesOnClose: { type: Boolean, default: false }
  }

  initialize() {
    this.element[this.identifier] = this
  }

  connect() {
    document.addEventListener('keyup', this.escClose)
    this.element.insertAdjacentHTML(
      'afterbegin',
      "<button type='button' class='modal-backdrop' tabindex='-1' data-action='modal#close'></button>"
    )

    this.initialInnerHTML = this.element.innerHTML

    if (this.isOpenValue) {
      this.open()
    } else {
      this.close()
    }
  }

  disconnect() {
    this.close()
    document.removeEventListener('keyup', this.escClose)
  }

  reset() {
    this.element.innerHTML = this.initialInnerHTML
  }

  open() {
    this.element.hidden = false
    document.body.style.overflowY = 'hidden'
  }

  close() {
    this.element.hidden = true
    document.body.style.overflowY = 'initial'

    if (this.resetIframesOnCloseValue) {
      this.resetIframes()
    }
  }

  resetIframes() {
    // if there are iframes in the element, and if there are videos for example, they would keep playing.
    // the following reset the state of the iframes (if any)
    const iframes = this.element.getElementsByTagName('iframe')

    for (let iframe of iframes) {
      iframe.setAttribute('src', iframe.getAttribute('src'))
    }
  }

  escClose = event => {
    if (event.key === 'Escape') this.close()
  }
}
