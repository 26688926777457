import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'item', 'search', 'label']
  static classes = ['selected']
  static values = { props: Object }

  get selectedClassWithDefault() {
    return this.hasSelectedClass ? this.selectedClass : 'selected'
  }

  select(event) {
    const { currentTarget } = event
    const value = currentTarget.getAttribute('data-value')
    this.inputTarget.value = value

    const selectedElements = currentTarget.parentElement.querySelectorAll('.selected')
    selectedElements.forEach(element => element.classList.remove(this.selectedClassWithDefault))

    currentTarget.classList.add(this.selectedClassWithDefault)

    if (this.propsValue.image_as_button_label) {
      const imageNode = currentTarget.firstElementChild
      const image = imageNode.cloneNode(true)
      this.setImageLabel(image)
    } else {
      this.setToggleLabel(currentTarget)
    }

    this.clearSearch()
  }

  search(event) {
    const value = event.currentTarget.value
    if (!value) {
      this.setItemsVisibility(true)
      return
    }

    const searchRegex = new RegExp(`^${value}(.*)`, 'i')
    this.setItemsVisibility(element => searchRegex.test(element.innerText.trim()))
  }

  setItemsVisibility(visible) {
    let isVisibleCallback

    switch (typeof visible) {
      case 'boolean':
        isVisibleCallback = () => visible
        break
      case 'function':
        isVisibleCallback = element => {
          return visible(element)
        }
        break
    }

    this.itemTargets.forEach(element => {
      element.classList.toggle('hidden', !isVisibleCallback(element))
    })
  }

  setToggleLabel(selectedItem) {
    const selectedText = selectedItem.innerText
    const selectedLeftImage = selectedItem.querySelector('.select-dropdown-item-left-image')

    const dropdownToggle = this.element.querySelector('.click-dropdown-toggle')

    const dropdownToggleText = dropdownToggle.querySelector('span')
    dropdownToggleText.innerText = selectedText

    const dropdownToggleLeftImage = dropdownToggle.querySelector('.select-dropdown-item-left-image')

    if (selectedLeftImage && dropdownToggleLeftImage) {
      const image = selectedLeftImage.cloneNode(true)
      dropdownToggle.replaceChild(image, dropdownToggle.firstElementChild)
    }
  }

  setImageLabel(image) {
    this.labelTarget.replaceChild(image, this.labelTarget.firstElementChild)
  }

  clearSearch() {
    if (!this.hasSearchTarget) return
    this.searchTarget.value = ''
    this.setItemsVisibility(true)
  }
}
