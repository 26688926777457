import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    userId: String,
    trackingAction: String,
    trackingCategory: String,
    trackingKey: String,
  }

  async trigger() {
    GoogleAnalyticsHelper.VentionGAEvent(
      this.trackingKeyValue,
      this.trackingActionValue,
      this.trackingCategoryValue,
      this.userIdValue
    )
  }
}
