import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selector', 'frame']
  static values = {
    autoEnable: Boolean
  }

  SELECTOR_ACTIVE_CLASS = 'content-switcher--selector--active'
  FRAME_ACTIVE_CLASS = 'content-switcher--frame--active'

  activeSelector = null
  selectorFrameMap = new Map()

  // Callback that can be set via outlet
  onSelectorActivate = null; 

  get currentFrame() {
    return this.selectorFrameMap.get(this.activeSelector)
  }

  connect() {
    this.selectorTargets.forEach((selector, i) => {
      const { frameKey } = selector.dataset

      this.frameTargets.forEach((frame, i) => {
        if (frame.dataset.frameKey === frameKey) {
          this.selectorFrameMap.set(selector, frame)
        }
      })

      selector.addEventListener('mouseenter', this.activateSelector.bind(this, selector))
    })

    if (this.autoEnableValue) {
      setTimeout(() => {
        this.activateSelector(this.selectorTargets[0])
      }, 0)
    }
  }

  activateSelector(selector) {
    this.deactivateCurrentSelector()

    this.activeSelector = selector;
    this.activeSelector.classList.add(this.SELECTOR_ACTIVE_CLASS)
    this.currentFrame?.classList.add(this.FRAME_ACTIVE_CLASS)

    this.onSelectorActivate?.(selector)
  }

  deactivateCurrentSelector() {
    this.deactivateSelector(this.activeSelector)
    this.activeSelector = null;
  }

  deactivateSelector(selector) {
    selector?.classList.remove(this.SELECTOR_ACTIVE_CLASS)
    this.selectorFrameMap.get(selector)?.classList.remove(this.FRAME_ACTIVE_CLASS)
  }
}