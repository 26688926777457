import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['dropdown', 'menu']
  static values = {
    closeOnScroll: Boolean
  }

  pageScrollHeight = 0

  toggle() {
    // cache scrollHeight before showing menu
    this.pageScrollHeight = this.getPageScrollHeight()

    this.dropdownTarget.classList.toggle('click-dropdown-expanded')
    this.menuTarget.classList.toggle('hidden')

    if (this.hasCloseOnScrollValue) {
      if (!this.isHidden) {
        document.addEventListener('scroll', this.closeOnScroll.bind(this))
      } else {
        document.removeEventListener('scroll', this.closeOnScroll.bind(this))
      }
    }

    // check if menu is off screen
    if (this.menuBottomPosition > this.pageScrollHeight) {
      this.menuTarget.classList.add('click-dropdown-expand-top')
    } else {
      this.menuTarget.classList.remove('click-dropdown-expand-top')
    }
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.isHidden) {
      this.toggle()
    }
  }

  closeOnScroll() {
    if (!this.isHidden) {
      this.toggle()
    }
  }

  getPageScrollHeight() {
    return document.body.scrollHeight
  }

  get isHidden() {
    return this.menuTarget.classList.contains('hidden')
  }

  get menuBottomPosition() {
    return this.menuTarget.getBoundingClientRect().bottom + document.documentElement.scrollTop
  }
}
