import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    options: Object,
  }

  initialize() {
    const defaults = {
      trigger: 'hover',
      container: 'body',
      placement: 'bottom',
      html: false,
    }
    const options = {
      ...defaults,
      ...this.optionsValue,
    }
    if (this.optionsValue.trigger === 'manual') {
      $(this.element)
        .popover(options)
        .on('mouseenter', function () {
          const _this = this
          $(this).popover('show')
          $('.popover').on('mouseleave', function () {
            $(_this).popover('hide')
          })
        })
        .on('mouseleave', function () {
          const _this = this
          setTimeout(function () {
            if (!$('.popover:hover').length) {
              $(_this).popover('hide')
            }
          }, 100)
        })
    } else {
      $(this.element).popover(options)
    }
  }
}
