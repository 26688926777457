import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'carouselOuter',
    'carouselInner',
    'carouselItem',
    'buttonLeftWrapper',
    'buttonRightWrapper',
    'buttonLeft',
    'buttonRight',
  ]
  static values = { itemGap: Number }

  initialize() {
    this.shouldShowCarouselArrows = this.shouldShowCarouselArrows.bind(this)
    this.checkIfMovable = this.checkIfMovable.bind(this)

    this.shouldShowCarouselArrows()
  }

  connect() {
    window.addEventListener('resize', this.shouldShowCarouselArrows)
  }

  disconnect() {
    window.removeEventListener('resize', this.shouldShowCarouselArrows)
  }

  shouldShowCarouselArrows() {
    const carouselWrapperRightPosition = this.carouselOuterTarget?.getBoundingClientRect().right
    const carouselInnerRightPosition = this.carouselInnerTarget?.getBoundingClientRect().right
    const carouselInnerWidth = this.carouselInnerTarget.offsetWidth
    const carouselOuterWidth = this.carouselOuterTarget.offsetWidth

    if (carouselInnerRightPosition && carouselWrapperRightPosition && this.carouselInnerTarget) {
      const carouselInnerLeftPosition = Number(this.carouselInnerTarget.style.left.split('px')[0])
      const rightSideOverflow = carouselInnerRightPosition > carouselWrapperRightPosition
      const leftSideOverflow = carouselInnerLeftPosition < 0

      if (carouselOuterWidth >= carouselInnerWidth) {
        this.carouselInnerTarget.style.left = '0px'
        this.buttonLeftWrapperTarget.classList.add('hidden')
        this.buttonRightWrapperTarget.classList.add('hidden')
      } else if (rightSideOverflow || leftSideOverflow) {
        this.buttonLeftWrapperTarget.classList.remove('hidden')
        this.buttonRightWrapperTarget.classList.remove('hidden')
      } else {
        this.buttonLeftWrapperTarget.classList.add('hidden')
        this.buttonRightWrapperTarget.classList.add('hidden')
      }
    }

    this.checkIfMovable()
  }

  checkIfMovable() {
    const carouselInnerLeftPosition = Number(this.carouselInnerTarget.style.left.split('px')[0])
    const carouselInnerWidth = this.carouselInnerTarget.offsetWidth
    const carouselOuterWidth = this.carouselOuterTarget.offsetWidth
    const buttonWidth = this.buttonLeftWrapperTarget.offsetWidth * 2
    const maxLeftPosition = -(carouselInnerWidth - carouselOuterWidth + buttonWidth)
    const canMoveRight = carouselInnerLeftPosition < 0
    const canMoveLeft = carouselInnerLeftPosition > maxLeftPosition

    if (canMoveLeft) {
      this.buttonRightTarget.classList.remove('disabled')
      this.buttonRightTarget.disabled = false
    } else {
      this.buttonRightTarget.classList.add('disabled')
      this.buttonRightTarget.disabled = true
    }

    if (canMoveRight) {
      this.buttonLeftTarget.classList.remove('disabled')
      this.buttonLeftTarget.disabled = false
    } else {
      this.buttonLeftTarget.classList.add('disabled')
      this.buttonLeftTarget.disabled = true
    }
  }

  moveCarousel(event) {
    const direction = event.currentTarget.dataset.direction

    const carouselInnerLeftPosition = Number(this.carouselInnerTarget.style.left.split('px')[0])
    const carouselItemWidth = this.carouselItemTarget.offsetWidth + this.itemGapValue
    const carouselInnerWidth = this.carouselInnerTarget.offsetWidth
    const carouselOuterWidth = this.carouselOuterTarget.offsetWidth
    const buttonWidth = this.buttonLeftWrapperTarget.offsetWidth * 2
    const maxLeftPosition = -(carouselInnerWidth - carouselOuterWidth + buttonWidth)
    const startPosition = 0

    if (direction === 'left') {
      const positionAfterMove = carouselInnerLeftPosition + carouselItemWidth
      const positionToMove =
        positionAfterMove > startPosition
          ? '0px'
          : `${carouselInnerLeftPosition + carouselItemWidth}px`

      this.carouselInnerTarget.style.left = positionToMove
    } else if (direction === 'right') {
      const positionAfterMove = carouselInnerLeftPosition - carouselItemWidth
      const positionToMove =
        positionAfterMove < maxLeftPosition
          ? `${maxLeftPosition}px`
          : `${carouselInnerLeftPosition - carouselItemWidth}px`

      this.carouselInnerTarget.style.left = positionToMove
    }

    this.checkIfMovable()
  }
}
