import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['page']

  CLASS_PAGE_ACTIVE = 'pager--page--active'
  CLASS_PAGE_LEFT = 'pager--page--left'
  
  activePage = null
  pageKeyPageMap = new Map()

  activatePageCallbacks = []
  historyStack = []

  didNavigateBack = false

  get activePageKey() {
    return this.activePage?.dataset.pageKey
  }

  connect() {
    this.resetPager()
  }

  resetPager() {
    this.historyStack = []
    this.activePage = null
    this.pageTargets.forEach(page => {
      page.classList.add('hidden')
      page.classList.remove(this.CLASS_PAGE_ACTIVE)
      const { pageKey } = page.dataset
      this.pageKeyPageMap.set(pageKey, page)
    })

    this.activatePage(this.pageTargets[0])
  }

  navigate(event) {
    const { pageKey } = event.currentTarget.dataset
    this.activatePage(this.pageKeyPageMap.get(pageKey))
  }

  activatePageKey(pageKey) {
    this.activatePage(this.pageKeyPageMap.get(pageKey))
  }

  activatePage(page) {
    if (this.activePage === page) return

    if (!this.didNavigateBack && this.activePageKey) {
      this.historyStack.push(this.activePageKey)
    }

    const prevPage = this.activePage

    page.classList.remove('hidden')

    this.activePage = page

    // need to wait for the next frame to prevent pop-in caused by hidden class
    requestAnimationFrame(() => {
      this.handleTransition(prevPage, page)
      this.didNavigateBack = false
      this.executeCallbacks(prevPage, page)
    })
  }

  handleTransition(prevPage, nextPage) {
    if (this.didNavigateBack) {
      // Navigating back: prevPage to the right and nextPage from left
      prevPage?.classList.remove(this.CLASS_PAGE_ACTIVE)
      nextPage?.classList.remove(this.CLASS_PAGE_LEFT)
      nextPage?.classList.add(this.CLASS_PAGE_ACTIVE)
    } else {
      // Navigating forward: prevPage to the left and nextPage from right
      prevPage?.classList.remove(this.CLASS_PAGE_ACTIVE)
      prevPage?.classList.add(this.CLASS_PAGE_LEFT)
      nextPage?.classList.add(this.CLASS_PAGE_ACTIVE)
    }

    // Remove prevPage from rendering after the transition is complete
    prevPage?.addEventListener('transitionend', () => {
      prevPage.classList.add('hidden')
    }, { once: true })
  }

  back() {
    const pageKey = this.historyStack.pop()
    const prevPage = this.pageKeyPageMap.get(pageKey)

    this.didNavigateBack = true
    this.activatePage(prevPage)
  }

  onPageChange(callback) {
    this.activatePageCallbacks.push(callback)
  }

  executeCallbacks(prevPage, nextPage) {
    this.activatePageCallbacks.forEach(callback => callback(prevPage, nextPage))
  }
}