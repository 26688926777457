import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    modalId: String,
  }
  connect() {
    let modalCloseButton

    if (this.hasModalIdValue) {
      modalCloseButton = document.getElementById(this.modalIdValue).querySelector('.modal-backdrop')
    } else {
      modalCloseButton = document.querySelector('.modal-backdrop')
    }

    if (modalCloseButton) modalCloseButton.click()
  }
}
