import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = {
    checkLocalStorage: Boolean,
  }

  initialize() {
    this.setConsentOnLocalStorage = this.setConsentOnLocalStorage.bind(this)

    if (this.checkLocalStorageValue) {
      this.checkLocalStorage()
    }
  }

  connect() {
    if (this.form) {
      this.form.addEventListener('submit', this.setConsentOnLocalStorage)
    }
  }

  disconnect() {
    if (this.form) {
      this.form.removeEventListener('submit', this.setConsentOnLocalStorage)
    }
  }

  checkLocalStorage() {
    if (!this.consentFromLocalStorage) {
      this.renderCookieConsent()
    }
  }

  renderCookieConsent() {
    const request = new FetchRequest('get', '/users/cookie_consent/edit', {
      responseKind: 'turbo-stream',
    })

    request.perform()
  }

  setConsentOnLocalStorage() {
    this.consentFromLocalStorage = Date.now()
  }

  get consentFromLocalStorage() {
    return window.localStorage.getItem('cookieConsentedAt')
  }

  set consentFromLocalStorage(value) {
    window.localStorage.setItem('cookieConsentedAt', value)
  }

  get form() {
    return this.element.querySelector('form')
  }
}
