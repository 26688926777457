// This should be a temporary hack. Ideally the whole SSR toaster system should be refactored to use Stimulus
// `window.displayToaster` is an alias of `applyConfiguration` defined in `app/assets/javascripts/toastrFlash.js`

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.displayToaster()
  }
}
