import { Controller } from "@hotwired/stimulus";
import { breakpoints } from "../constants/Layout";

export default class extends Controller {
  static values = { index: Number };

  FIXED_NAV_SCROLL_HEIGHT_MOBILE = 0;
  STICKY_NAV_CLASS = "new-navbar--parent--sticky";

  navParent = null;
  careersSecondaryNav = null;
  careersSecondaryNavDefaultTop = null;

  fixedNavScrollHeight = 0;
  lastScrollTop = 0;

  connect() {
    this.lastScrollTop = window.scrollY;
    this.navParent = document.querySelector(".new-navbar--parent");
    this.careersSecondaryNav = document.querySelector(
      ".careers-secondary-stick-nav-bar",
    );

    this.navOffsetHeight = 0;
    this.utilityBarHeight = this.navOffsetHeight;

    if (this.careersSecondaryNav) {
      this.careersSecondaryNavDefaultTop = Number(
        getComputedStyle(this.careersSecondaryNav).top.match(/\d+/)[0],
      );
      this.careersSecondaryNav.style.top = `${this.careersSecondaryNavDefaultTop + this.navOffsetHeight}px`;
    }

    window.addEventListener("scroll", this.handleScroll.bind(this));
    window.addEventListener("resize", this.handleResize.bind(this));

    this.handleScroll();
  }

  handleScroll() {
    this.calculateFixedNavScrollHeight();

    const scrollTop = window.scrollY;
    const shouldStickNav = scrollTop > this.fixedNavScrollHeight;
    const hasStickyNav = this.navParent.classList.contains(
      this.STICKY_NAV_CLASS,
    );
    const isScrollingDown = scrollTop > this.lastScrollTop;

    if (!isScrollingDown) {
      document.body.classList.remove("body--short-navbar");
      if (this.careersSecondaryNav) {
        this.careersSecondaryNav.style.top = `${this.careersSecondaryNavDefaultTop + this.navOffsetHeight}px`;
      }
    }

    if (shouldStickNav && !hasStickyNav) {
      this.navParent.classList.add(this.STICKY_NAV_CLASS);
    } else if (
      hasStickyNav &&
      scrollTop <=
        Math.max(this.fixedNavScrollHeight - this.utilityBarHeight, 0)
    ) {
      this.navParent.classList.remove(this.STICKY_NAV_CLASS);
    }

    this.lastScrollTop = scrollTop;
  }

  handleResize() {
    this.calculateFixedNavScrollHeight();
  }

  calculateFixedNavScrollHeight() {
    const headerBanners = document.querySelector("#header_banners");
    const bannerHeight = headerBanners ? headerBanners.clientHeight : 0;

    this.utilityBarHeight =
      window.innerWidth > breakpoints.navMainLarge
        ? 0
        : this.FIXED_NAV_SCROLL_HEIGHT_MOBILE;

    this.fixedNavScrollHeight = this.utilityBarHeight + bannerHeight;
  }

  openMobileNav() {
    this.toggleMobileNav(true);
  }

  closeMobileNav() {
    this.toggleMobileNav(false);
  }

  toggleMobileNav(isOpen) {
    const mobileDropdown = document.querySelector(".navbar-v2-mobile-dropdown");
    const navbarMobileIconButtons = document.querySelector(
      ".navbar-v2-mobile-icon-buttons",
    );
    const navbarMobileCloseButton = document.querySelector(
      ".navbar-v2-mobile-close-button",
    );

    if (
      !mobileDropdown ||
      !navbarMobileIconButtons ||
      !navbarMobileCloseButton
    ) {
      return;
    }

    mobileDropdown.classList.toggle("navbar-v2-active", isOpen);
    mobileDropdown.classList.toggle("navbar-v2-hidden", !isOpen);

    navbarMobileIconButtons.classList.toggle("navbar-v2-active", !isOpen);
    navbarMobileIconButtons.classList.toggle("navbar-v2-hidden", isOpen);

    navbarMobileCloseButton.classList.toggle("navbar-v2-active", isOpen);
    navbarMobileCloseButton.classList.toggle("navbar-v2-hidden", !isOpen);
  }

  toggleMobileDropdown() {
    this.toggleDropdown(
      "navbar-v2-mobile-category-wrapper",
      "navbar-v2-mobile-expand-button",
    );
  }

  toggleDesktopDropdown() {
    this.toggleDropdown("navbar-v2-menu-dropdown", "navbar-v2-menu-item");
  }

  toggleDropdown(dropdownClass, buttonClass) {
    const index = this.indexValue;
    const dropdowns = document.getElementsByClassName(dropdownClass);
    const buttons = document.getElementsByClassName(buttonClass);

    if (
      dropdowns.length === 0 ||
      buttons.length === 0 ||
      index >= dropdowns.length ||
      index >= buttons.length
    ) {
      return;
    }

    const isActive = dropdowns[index].classList.contains("navbar-v2-active");
    dropdowns[index].classList.toggle("navbar-v2-active", !isActive);
    buttons[index].classList.toggle("navbar-v2-active", !isActive);

    Array.from(dropdowns).forEach((dropdown, i) => {
      if (i !== index) {
        dropdown.classList.remove("navbar-v2-active");
      }
    });

    Array.from(buttons).forEach((button, i) => {
      if (i !== index) {
        button.classList.remove("navbar-v2-active");
      }
    });
  }

  closeDesktopDropdown() {
    this.closeDropdown("navbar-v2-menu-dropdown", "navbar-v2-menu-item");
  }

  closeDropdown(dropdownClass, buttonClass) {
    const dropdowns = document.getElementsByClassName(dropdownClass);
    const buttons = document.getElementsByClassName(buttonClass);

    Array.from(dropdowns).forEach((dropdown) => {
      dropdown.classList.remove("navbar-v2-active");
    });

    Array.from(buttons).forEach((button) => {
      button.classList.remove("navbar-v2-active");
    });
  }
}
