import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        redirectionUrls: String
    }

    connect() {
        const redirectionUrlsJson = JSON.parse(this.redirectionUrlsValue)

        this.element.onsubmit = function() {
            const allLocales = Object.keys(redirectionUrlsJson)
            const selectedReturnTo = this.querySelector('input[name="return_to"]').value
            const selectedLocale = allLocales.find(locale => redirectionUrlsJson[locale] === selectedReturnTo)

            this.querySelector('input[name="locale"]').value = selectedLocale
        }
    }
}
