// To disable all turbo buttons on form submit

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.reset = this.reset.bind(this)
    this.changeButtonText = this.changeButtonText.bind(this)
    this.originalText = this.element.value || this.element.textContent
    this.disabledText = this.element.dataset.disableWith
  }

  connect() {
    document.addEventListener('turbo:submit-end', this.reset)

    if (this.element.form) {
      this.element.form.addEventListener('submit', this.changeButtonText)
    } else if (this.element.dataset.disableWith) {
      this.element.addEventListener('click', this.changeButtonText)
    }
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.reset)

    if (this.element.form) {
      this.element.form.removeEventListener('submit', this.changeButtonText)
    }
  }

  changeButtonText() {
    if (this.disabledText) {
      this.modifyElementText(this.element, this.disabledText)
    }
  }

  reset() {
    this.element.disabled = false
    this.modifyElementText(this.element, this.originalText)
  }

  modifyElementText(element, text) {
    if (this.disabledText) {
      if (element.value) {
        element.value = text
      } else {
        element.textContent = text
      }
    }
  }
}
